import { Paper, Typography } from '@mui/material';
import { Route, Outlet } from 'react-router';
import { UserList } from './UserList';
import { User } from './User';

export const UsersPage = () => {
  return <>
    <Typography variant='h2'>Users</Typography>
    <Paper sx={{ p: 2 }}>
      <Outlet/>
    </Paper>
  </>;
}

export const UserRouter = <Route path='users' element={<UsersPage/>}>
  <Route index element={<UserList/>} />
  <Route path='create' element={<h1>Here you would create users</h1>} />
  <Route path=':id' element={<User />} />
  <Route path='' element={<h1>UserRouter, Not found</h1>} /> {/* Not actually needed.. */}
</Route>;