import { IsDate, IsNotEmpty, IsOptional } from 'class-validator';
import { PartialType } from './type-helpers/partial-type';
import { BaseEntity } from './base-entity';
import { Type } from 'class-transformer';

export class PublicNewsDto extends BaseEntity {
  @IsNotEmpty()
  title!: string;

  @IsNotEmpty()
  contentMd!: string;

  @IsOptional()
  shortText?: string;

  @IsDate()
  @Type(() => Date)
  @IsOptional()
  publishDate: Date = new Date();
}

export class UpdateNewsDto extends PartialType(PublicNewsDto) {}
