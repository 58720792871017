import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { countries } from 'public-dto';


export const CountrySelect = ({ name, control, label }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          notEmptyTesting: (v) => {
            return v?.code.length > 0 ? undefined : `[${name}] should not be empty!`;
          }
        }
      }}
      render={({
        field: { onChange, value, ref },
        fieldState: { error },
        // formState,
      }) => (
        <Autocomplete
          id='country-select'
          onChange={(_event: any, newValue: string | null) => {
            onChange(newValue);
          }}
          value={value || null}
          options={countries}
          autoHighlight
          getOptionLabel={(option) => `${option.label} (${option.code})`}
          renderOption={(props, option) => (
            <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading='lazy'
                width='20'
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=''
              />
              {option.label} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={ref}
              label={label}
              variant='standard'
              helperText={error ? error.message : null}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
    />
  );
};
