import { getGridDateOperators, getGridNumericOperators, getGridStringOperators, GridFilterModel } from "@mui/x-data-grid";

export type FilterOperationKey = 'is' | 'after' | 'before' | 'isEmpty'
  | 'contains' | 'equals' | 'isEmpty' | '=' | '!=' | '<' | '<=' | '>' | '>=' | 'isEmpty';

  export const dateOperators = getGridDateOperators().filter(
    (op) => ['is', 'after', 'before', 'isEmpty'].includes(op.value)
  );
  export const stringOperators = getGridStringOperators().filter(
    (op) => ['contains', 'equals', 'isEmpty'].includes(op.value)
  );
  export const numericOperators = getGridNumericOperators().filter(
    (op) => ['=', '!=', '<', '<=', '>', '>=', 'isEmpty'].includes(op.value)
  );

  const dateOperatorMapper = {
    'is': 'eq',
    'after': 'gt',
    'before': 'lt',
  };
  const stringOperatorMapper = {
    'contains': 'ilike',
    'equals': 'eq',
  };
  const numericOperatorMapper = {
    '=': 'eq',
    '!=': 'ne',
    '<': 'lt',
    '<=': 'lte',
    '>': 'gt',
    '>=': 'gte',
  };

  export const operatorMapper = {
    ...dateOperatorMapper,
    ...stringOperatorMapper,
    ...numericOperatorMapper,
    'isEmpty': 'isEmpty',
  };

  export const getFilterString = (filterModel: GridFilterModel): string | undefined => {
    if(!filterModel?.items?.[0]) {
      return;
    }
    const { columnField, operatorValue, value } = filterModel.items[0];
    if (columnField && operatorValue) {
      const operator = operatorMapper[operatorValue as FilterOperationKey];
      if (operatorValue === 'isEmpty') {
        return `${columnField}:isEmpty:null`;
      } else if (operator && value){
        return `${columnField}:${operator}:${value}`;
      }
    }
  };