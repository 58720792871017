import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export const Footer = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://nokura.io/">
        Nokura
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};