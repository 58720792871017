import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import axios from 'axios';

export const DashBoard = () => {
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [quote, setQuote] = useState('fetching ...');
  useEffect(() => {
    let active = true;
    async function getQuote() {
      const quoteObj = await axios.get('https://quote-garden.herokuapp.com/api/v3/quotes/random')
        .then((data) => {
          return data?.data?.data?.[0];
        });
      if (!active) { return; }
      if (quoteObj && quoteObj.quoteText && quoteObj.quoteAuthor) {
        setQuote(`${quoteObj.quoteText} — ${quoteObj.quoteAuthor}`);
      } else {
        setQuote('Quote server didn\'t respond 🤷‍♂️');
      }
    }
    getQuote();
    return () => { active = false; }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Grid container spacing={3}>
  {/* Chart */}
  <Grid item xs={12} md={7}>
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
      }}
    >
      {
        (!isAuthenticated || isLoading || !user) &&
        <Typography variant='h3'>Not Authenticated</Typography>
      }
      {
        isAuthenticated && user &&
        <Box>
          <Typography variant='h4'>Welcome {user.name}</Typography>
          <Typography variant='body1' mt={2}>Username: {user.email}</Typography>
        </Box>
      }
    </Paper>
  </Grid>
  <Grid item xs={12} md={5}>
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 240,
      }}
    >
      <Typography variant='h5'>T-Bag anecdote of the day:</Typography>
      <Typography variant='body1' mt={2}>{quote}</Typography>
    </Paper>
  </Grid>
</Grid>;
};