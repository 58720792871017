import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormInputProps } from './FormInputProps';

/*
The important part here is the Controller:
<Controller
  control={control}
  name="test"
  render={({
    field: { onChange, onBlur, value, name, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState,
  }) => ( WHATEVER_INPUT_WE_WANT )}
/>

We need to use react-hook-form's controller since MUI doesn't let us pass refs to the actual input element.
*/

// TODO: Check if this is really needed
const parseValue = (v: string, type: React.InputHTMLAttributes<unknown>['type']) => {
  switch (type) {
    case 'number':
      const num = parseInt(v);
      return !isNaN(num) ? num : '';
    // add cases here if needed
    default:
      return v || '';
  }
};

export const FormInputText = ({ name, control, label, disabled, type, validator }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      // NOTE: This is not used since hookform/validator has class-validator already handled
      rules={{
        validate: {
          customValidator: (v) => {
            return validator && typeof validator === 'function'
              ? validator(v)
              : true;
          }
        }
      }}
      render={({
        field: { onChange, value, ref },
        fieldState: { error },
        // formState,
      }) => (
        <TextField
          helperText={error ? error.message : null}
          size='medium'
          error={!!error}
          onChange={onChange}
          value={parseValue(value, type)}
          fullWidth
          label={label}
          variant='standard'
          disabled={!!disabled}
          type={type || 'text'}
          inputRef={ref}
        />
      )}
    />
  );
};