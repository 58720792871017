import { IsDate, IsNotEmpty, IsOptional } from 'class-validator';
import { PartialType } from './type-helpers/partial-type';
import { BaseEntity } from './base-entity';

export class PublicGigDto extends BaseEntity {
  @IsNotEmpty()
  title!: string;

  @IsNotEmpty()
  contentMd!: string;

  @IsOptional()
  shortText?: string;

  //----------
  @IsOptional()
  @IsDate()
  gigDate!: Date;

  @IsOptional()
  @IsDate()
  publishDate?: Date = new Date();

  @IsOptional()
  additionalInfo?: any;

  // TODO: Venue here, and venue should contain the city..
  // @ManyToOne()
  // venue!: Venue;
}

export class UpdateGigDto extends PartialType(PublicGigDto) {}
