import { Button, Box, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../../styles/theme';
import './loginPage.css';

export const LoginPage = () => {
  const { loginWithRedirect, logout, isAuthenticated, error } = useAuth0();
  const auth0Error = error as { error: string, error_description: string } | undefined;
  const fireFlies = Array.from(Array(15).keys());
  const fontColor = '#ebf1fa';

  const handleLogin = () => {
    loginWithRedirect({ prompt: 'select_account' });
  }

  const handleLogout = () => {
    logout();
  }

  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        {fireFlies.map((f, i) => (
          <div key={i} className="firefly" />
        ))}
        <Box className="login-container">
          <Box
            display="flex"
            flexDirection='column'
            alignItems='center'
            pt={20}
          >
            <Box
              textAlign='center'
              width='90%'
              maxWidth='600px'
              sx={{
                background: '#0a0d0be3',
                borderRadius: '16px',
                boxShadow: 20
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 350,
                  width: 350,
                  // maxHeight: { xs: 233, md: 167 },
                  // maxWidth: { xs: 350, md: 250 },
                }}
                alt='Battle Beast hawk symbol'
                src='/login-hawk-min.png'
              />
              {/* <Typography variant="h2" color={fontColor} mb={4} >Login</Typography> */}
              <Box>
                <Button
                  color="info"
                  variant="contained"
                  size="large"
                  onClick={isAuthenticated ? handleLogout : handleLogin}
                  sx={{ mr: 2 }}
                >
                  LOGIN
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  size="large"
                  onClick={handleLogout}
                >
                  LOGOUT
                </Button>
              </Box>
              <Box minHeight='100px' p={5} >
                {auth0Error &&
                  <>
                    <Typography variant='h5' color={fontColor}>{auth0Error.error}</Typography>
                    <Typography variant='body1' color={fontColor}>{auth0Error.error_description}</Typography>
                  </>
                }
                {!auth0Error &&
                  <>
                    <Typography variant='body1' color={fontColor}>Press the 'Login' to start authenticating with your Google account</Typography>
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Box>
    </ThemeProvider>
  );
};
