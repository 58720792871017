import { useAuthenticatedRequest } from "./useAuthenticadedFetch";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";

interface IAppQueryOptions {
  path: string;
  enabled?: boolean;
  axiosConfig?: AxiosRequestConfig;
  reactQueryOptions?: any; // Use carefully. Typing this parameter was hard..
}

export const useApiQuery = <T>(options: IAppQueryOptions) => {
  const authenticatedQuery = useAuthenticatedRequest<T>();
  // Note: Using useMemo here. Copied from shopify example. Not 100% sure if this helps anything..
  const fetch = useMemo(() => {
    return async () => {
      const responseData = await authenticatedQuery(options.path, options.axiosConfig || {});
      return responseData;
    }
  }, [options.path, options.axiosConfig, authenticatedQuery]);
  return useQuery([options], fetch, {
    ...options.reactQueryOptions,
    refetchOnWindowFocus: false,
    enabled: options.enabled,
  });
}