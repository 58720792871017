import { Button, Grid, Typography } from "@mui/material";
import { PublicNewsDto, UpdateNewsDto } from "public-dto";
import { useNavigate, useParams } from "react-router";
import { toStr, typedEntries } from "../../../util/helpers";
import { useApiQuery } from '../../../hooks/useApiQuery';
import { useQueryClient } from "@tanstack/react-query";
import { useApiMutation } from "../../../hooks/useApiMutation";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { FormInputText } from "../../form-components/FormInputText";
import { FormInputRichText } from "../../form-components/FormInputRichText";
import { classValidatorResolver } from '@hookform/resolvers/class-validator';

export const NewsItem = () => {
  const { id } = useParams();
  const creatingNew = isNaN(parseInt(id || ''));
  const navigate = useNavigate();
  const queryKey = { path: `news/${id}`, enabled: !creatingNew };
  const { isLoading, error, data } = useApiQuery<PublicNewsDto>(queryKey);
  const queryClient = useQueryClient();
  const patchMutation = useApiMutation<UpdateNewsDto>({ path: `news/${id}`, axiosConfig: { method: 'patch' } });
  const postMutation = useApiMutation<UpdateNewsDto>({ path: 'news', axiosConfig: { method: 'post' } });
  const resolver = classValidatorResolver(PublicNewsDto);
  const { handleSubmit, reset, control, setValue, formState: { errors } } = useForm<UpdateNewsDto>({ reValidateMode: 'onChange', resolver });
  const onSubmit = (data: UpdateNewsDto) => {
    if (creatingNew) {
      postMutation.mutate(data, {
        onError: (err) => {
          console.log(err);
        },
        onSuccess: (mutatedData) => {
          console.log(mutatedData);
          navigate(`/news/${mutatedData.id}`);
        }
      })
    } else {
      patchMutation.mutate(data, {
        onError: (err) => {
          console.log(err);
        },
        onSuccess: (mutatedData) => {
          console.log(mutatedData);
          queryClient.setQueryData([queryKey], mutatedData);
        }
      });
    }
  };
  useEffect(() => {
    if (data) {
      typedEntries(data).forEach(e => {
        const name = e?.[0];
        const val = e?.[1];
        if (name && val) {
          setValue(name, val);
        }
      })
    }
  }, [data, setValue]);
  return (<>
    { !creatingNew && isLoading && <p>Loading news article '{id}'</p> }
    { error && <p>Error: {toStr(error)}</p> }
    {
      (!isLoading || creatingNew) && !error && (data || creatingNew) && <>
        <form>
          <Grid container spacing={2} maxWidth='md'>
            {
              !creatingNew &&
              <Grid item xs={12}>
                <FormInputText name='id' control={control} type='number' disabled label='Id' />
              </Grid>
            }
            <Grid item xs={12}>
              <FormInputText name='title' control={control} label='Title' />
            </Grid>
            <Grid item xs={12}>
              <FormInputText name='shortText' control={control} label='Short Text' />
            </Grid>
            <Grid item xs={12}>
              <FormInputRichText name='contentMd' control={control} label='Content' />
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='primary' onClick={handleSubmit(onSubmit)} sx={{ mr: 2, my: 2 }}>Submit</Button>
              <Button variant='outlined' color='secondary' onClick={() => reset()}>Reset</Button>
            </Grid>
          </Grid>
        </form>
      </>
    }
    {
      !isLoading && !error && data && <>
        <Typography variant='h3'>{data.title}</Typography>
        <pre>{toStr(data)}</pre>
      </>
    }
    {
      errors && <>
        <Typography variant='h3'>Error or no?</Typography>
        <pre>{toStr(errors)}</pre>
      </>
    }
  </>);
}