import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../styles/theme';
import { Routes, Route } from "react-router-dom";
import { GlobalContext } from './globalContext';
import { MainLayout } from './MainLayout';
import { DashBoard } from '../pages/dashboard/Dashboard';
import { NotFoundPage } from '../pages/error-pages/NotFoundPage';
import { MailingListPage } from '../pages/mailing-list/MailingListPage';
import { LinkTreePage } from '../pages/linktree/LinkTreePage';
import { MediaLibraryPage } from '../pages/media-library/MediaLibraryPage';
import { UserRouter } from '../pages/users/UsersPage';
import { VenuesRouter } from '../pages/venues/VenuesPages';
import { GigsRouter } from '../pages/gigs/GigsPage';
import { CitiesRouter } from '../pages/cities/CitiesPage';
import { NewsRouter } from '../pages/news/NewsPage';
import { useAuth0 } from '@auth0/auth0-react';
import { LoaderPage } from '../pages/loader/LoaderPage';
import { ProtectedRoute } from '../shared/protected-route';
import { LoginPage } from '../pages/login/LoginPage';
import './app.css';

function App() {
  // TODO: Init menuOpen by screen size. Or use localstorage and remember the state
  // TODO: #2 Opening menu currently re-renders the main content. How to prevent that?
  const [menuOpen, setMenuOpen] = useState(false);
  const { isLoading, isAuthenticated } = useAuth0();
  if (isLoading) {
    return <LoaderPage />;
  }
  if (!isAuthenticated) {
    return <LoginPage />
  }
  return (
    <>
      <GlobalContext.Provider value={{ menuOpen, setMenuOpen }}>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Routes>
              <Route element={<ProtectedRoute component={MainLayout} />} >
                <Route path='/' element={<DashBoard/>} />
                {[
                  <React.Fragment key='1'>{NewsRouter}</React.Fragment>,
                  <React.Fragment key='2'>{GigsRouter}</React.Fragment>,
                  <React.Fragment key='3'>{VenuesRouter}</React.Fragment>,
                  <React.Fragment key='4'>{CitiesRouter}</React.Fragment>,
                  <React.Fragment key='5'>{UserRouter}</React.Fragment>,
                ]}
                <Route path='/media-library' element={<MediaLibraryPage />} />
                <Route path='/linktree' element={<LinkTreePage />} />
                <Route path='/mailing-list' element={<MailingListPage />} />
                <Route path='*' element={<NotFoundPage />} />
              </Route>
            </Routes>
          </Box>
        </ThemeProvider>
      </GlobalContext.Provider>
    </>
  );
}

export default App;
