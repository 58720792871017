import { Typography } from "@mui/material";
import { PublicUserDto } from "public-dto";
import { useParams } from "react-router";
import { toStr } from "../../../util/helpers";
import { useApiQuery } from '../../../hooks/useApiQuery';

export const User = () => {
  const { id } = useParams();
  const { isLoading, error, data } = useApiQuery<PublicUserDto>({ path: `users/${id}` });
  return (<>
    { isLoading && <p>Loading user "{id}"</p> }
    { error && <p>Error: {toStr(error)}</p> }
    {
      !isLoading && !error && data && data && <>
        <Typography variant='h3'>{data.email}</Typography>
        <pre>{toStr(data)}</pre>
      </>

    }
  </>);
}