import { useAuthenticatedRequest } from "./useAuthenticadedFetch";
import { useMutation } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";

interface IAppQueryOptions {
  path: string;
  axiosConfig?: AxiosRequestConfig;
  reactQueryOptions?: any; // Use carefully. Typing this parameter was hard..
}

export const useApiMutation = <T>(options: IAppQueryOptions) => {
  const authenticatedQuery = useAuthenticatedRequest<T>();
  return useMutation({
    mutationFn: (data: T) => {
      const axiosConfigWithBody = {
        headers: {
          ...options.axiosConfig?.headers,
          'Content-Type': 'application/json',
        },
        ...options.axiosConfig,
        data,
      };
      return authenticatedQuery(options.path, axiosConfigWithBody);
    }
  });
}