import { Type } from 'class-transformer';
import { IsDate, IsNumber, IsOptional } from 'class-validator';

// Currently setting all optinal here since couldn't make intersection type work
export class BaseEntity {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsDate()
  @Type(() => Date)
  @IsOptional()
  createdAt?: Date;

  @IsDate()
  @Type(() => Date)
  @IsOptional()
  updatedAt?: Date;
}
