import { useAuth0 } from "@auth0/auth0-react";
import { apiUrl } from '../util/constants';
import axios, { AxiosRequestConfig } from "axios";

export function useAuthenticatedRequest<T>() {
  const auth = useAuth0();
  return async (url: string, config: AxiosRequestConfig) => {
    const token = await auth.getAccessTokenSilently();
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`
    };
    config.baseURL = apiUrl;
    config.url = url;
    const response = await axios.request<T>(config).then( res => res.data );
    //checkHeadersForReauthorization(response.headers)
    return response;
  }
}

// Example from shopify how we could check reauthorization after getting response in
// useAuthenticatedGet
/*
function checkHeadersForReauthorization(headers, app) {
  if (headers.get("X-Shopify-API-Request-Failure-Reauthorize") === "1") {
    const authUrlHeader =
      headers.get("X-Shopify-API-Request-Failure-Reauthorize-Url") ||
      `/api/auth`;

    const redirect = Redirect.create(app);
    redirect.dispatch(
      Redirect.Action.REMOTE,
      authUrlHeader.startsWith("/")
        ? `https://${window.location.host}${authUrlHeader}`
        : authUrlHeader
    );
  }
}
*/