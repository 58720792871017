import { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import {
  numericOperators,
  stringOperators,
  dateOperators,
  getFilterString
} from '../../../util/filterMappers';
import AddIcon from '@mui/icons-material/Add';
import { useApiQuery } from '../../../hooks/useApiQuery';
import { PagedResult } from '../../../util/PagedResultType';
import { PublicCityDto } from 'public-dto';
import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  GridToolbar,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

export const NewsList = () => {

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 60, filterOperators: numericOperators },
    { field: 'title', headerName: 'Title', width: 300, filterOperators: stringOperators },
    { field: 'contentMd', headerName: 'Content Md', width: 300, filterOperators: stringOperators },
    { field: 'publishDate', headerName: 'Publish Date', width: 150, filterOperators: dateOperators },
    { field: 'createdAt', headerName: 'Created', width: 150, filterOperators: dateOperators },
    { field: 'updatedAt', headerName: 'Updated', width: 150, filterOperators: dateOperators },
  ];

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const defaultOrdering = { order: 'ASC', orderBy: 'id' };
  const [ordering, setOrdering] = useState(defaultOrdering);
  const [filterQuery, setFilterQuery] = useState<string | undefined>();

  const queryOptions = useMemo(
    () => ({
      offset,
      limit,
      ...ordering,
      q: filterQuery,
    }),
    [offset, limit, ordering, filterQuery],
  );

  const {
    isError,
    error,
    data,
    isLoading,
    isFetching,
  } = useApiQuery<PagedResult<PublicCityDto>>({
    path: 'news',
    axiosConfig: { params: queryOptions },
    reactQueryOptions: { keepPreviousData: true },
  });

  const [rowCountState, setRowCountState] = useState(data?.meta?.itemCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) => {
      return data?.meta?.itemCount !== undefined
        ? data?.meta?.itemCount
        : prevRowCountState;
    });
  }, [data?.meta.itemCount, rowCountState]);

  const onPageChange = (newPage: number) => {
    setOffset(limit * newPage);
  };

  const getPage = () => {
    const res = (data?.meta?.page || 1) - 1;
    return res;
  }

  const onPageSizeChange = (pageSize: number) => {
    setLimit(pageSize);
  }

  const onSortModelChange = useCallback((sortModel: GridSortModel) => {
    const model = sortModel?.[0];
    if (model && model.field) {
      const direction = model.sort === 'asc' ? 'ASC' : 'DESC';
      setOrdering({
        order: direction,
        orderBy: model.field,
      })
    }
    else {
      setOrdering(defaultOrdering);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    setFilterQuery(getFilterString(filterModel));
  };

  return (
    <>
    {isError && <Typography variant="caption">{JSON.stringify(error, null, 2)}</Typography>}
    {!error && (
      <Box sx={{ height: 'auto', overflow: 'auto' }}>
        <Box my={2}>
          <Button
            startIcon={<AddIcon />}
            variant='contained'
            color='primary'
            onClick={() => navigate('/news/create')}
          >Create News Article</Button>
        </Box>
        <DataGrid
          columns={columns}
          autoHeight={true}
          editMode='row'
          disableSelectionOnClick={true}
          checkboxSelection={false}
          components={{
            Toolbar: GridToolbar,
          }}
          onRowClick={(params) => {
            navigate(`/news/${params.id}`);
          }}
          loading={isFetching || isLoading}
          // Note that we don't let data be empty since using react query keepPreviousData
          // otherwise the grid will reset paging, sort and filter values while waiting for new data
          rows={data?.data || []}
          // Pagination / Fetching
          paginationMode='server'
          rowCount={rowCountState}
          pageSize={limit}
          onPageChange={onPageChange}
          page={getPage()} // NOTE! This is important prop. Grid acts weird otherwise
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          // Sorting
          sortingMode='server'
          onSortModelChange={onSortModelChange}
          sortModel={[{ field: ordering.orderBy, sort: ordering.order === 'ASC' ? 'asc' : 'desc'  }]}
          // Filtering
          filterMode='server'
          onFilterModelChange={onFilterModelChange}
        />
      </Box>
    )}
    </>
  );
};
