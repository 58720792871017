import { IsNotEmpty, IsOptional } from 'class-validator';
import { PublicCityDto } from './city.dto';
import { PartialType } from './type-helpers/partial-type';
import { BaseEntity } from './base-entity';

export class PublicVenueDto extends BaseEntity {
  @IsNotEmpty()
  name!: string;

  @IsOptional()
  cityId?: number;

  @IsOptional()
  additionalInfo?: any;

  @IsOptional()
  city?: PublicCityDto;
}

export class UpdateVenueDto extends PartialType(PublicVenueDto) {}

// export class PublicVenueDto extends IntersectionType(CreateVenueDto, BaseEntity) {}
