import safeStringify from 'fast-safe-stringify';
// TODO: Share helpers between api and ui

export const jPrint = (msg: unknown) => {
  console.log(safeStringify(msg, undefined, 2));
};

export const toStr = (obj: unknown) => {
  if (obj && typeof obj === 'string') {
    return obj;
  }
  return safeStringify(obj, undefined, 2);
};

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function typedEntries<T extends Object>(obj: T): Entries<T> {
  return Object.entries(obj) as any;
}