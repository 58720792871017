import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { Editor } from '@tinymce/tinymce-react';


export const FormInputRichText = ({ name, control, label, validator }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          customValidator: (v) => {
            return validator && typeof validator === 'function'
              ? validator(v)
              : true;
          }
        }
      }}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <>
          <Typography variant='h5'>{label}</Typography>
          <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            // initialValue={''}
            value={value}
            disabled={false}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'image',
              ],
              toolbar: 'undo redo | blocks | image | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              images_upload_handler: async (blobInfo: any, _progress: any): Promise<string> => {
                console.log(blobInfo.filename());
                return 'this.should.be.uploaded.file.name.or.something.jpg';
              },
              images_upload_credentials: true,
              // images_reuse_filename: true,
            }}
            onEditorChange={onChange}
          />
          {
            // TODO: Make this nicer later..
            error?.message &&
            <Box><pre>{error.message}</pre></Box>
          }
        </>
      )}
    />
  );
};
