import { Paper, Typography } from '@mui/material';
import { Route, Outlet } from 'react-router';
import { NewsList } from './NewsList';
import { NewsItem } from './NewsItem';

export const NewsPage = () => {
  return <>
    <Typography variant='h2'>News</Typography>
    <Paper sx={{ p: 2 }}>
      <Outlet/>
    </Paper>
  </>;
}

export const NewsRouter = <Route path='news' element={<NewsPage/>}>
  <Route index element={<NewsList/>} />
  <Route path='create' element={<NewsItem />} />
  <Route path=':id' element={<NewsItem />} />
  <Route path='' element={<h1>Not found</h1>} /> {/* Not actually needed.. */}
</Route>;