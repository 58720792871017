import { Paper, Typography } from '@mui/material';
import { Route, Outlet } from 'react-router';
import { GigList } from './GigList';
import { Gig } from './Gig';

export const GigsPage = () => {
  return <>
    <Typography variant='h2'>Gigs</Typography>
    <Paper sx={{ p: 2 }}>
      <Outlet />
    </Paper>
  </>;
}

export const GigsRouter = <Route path='/gigs' element={<GigsPage/>}>
  <Route index element={<GigList/>} />
  <Route path='create' element={<h1>Create Gig here</h1>} />
  <Route path=':id' element={<Gig />} />
  <Route path='' element={<h1>GigsRouter, Not Found</h1>} /> {/* Not actually needed.. */}
</Route>