import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import FeedIcon from '@mui/icons-material/FeedOutlined';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import FestivalIcon from '@mui/icons-material/Festival';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import SignpostIcon from '@mui/icons-material/Signpost';
import OutboxIcon from '@mui/icons-material/Outbox';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import { Link as ReactRouterLink } from 'react-router-dom';

const RouterLink = styled(ReactRouterLink)(({ theme }) => ({
  color: grey[700],
  textDecoration: 'none',
  '&:hover': {
    color: grey[900],
  }
}));

export const mainListItems = (
  <React.Fragment>

    <RouterLink to='/'>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </RouterLink>

    <RouterLink to='/news'>
      <ListItemButton>
        <ListItemIcon>
          <FeedIcon />
        </ListItemIcon>
        <ListItemText primary="News" />
      </ListItemButton>
    </RouterLink>

    <RouterLink to='/gigs'>
      <ListItemButton>
        <ListItemIcon>
          <AirportShuttleIcon />
        </ListItemIcon>
        <ListItemText primary="Gigs" />
      </ListItemButton>
    </RouterLink>

    <RouterLink to='/venues'>
      <ListItemButton>
        <ListItemIcon>
          <FestivalIcon />
        </ListItemIcon>
        <ListItemText primary="Venues" />
      </ListItemButton>
    </RouterLink>

    <RouterLink to='/cities'>
      <ListItemButton>
        <ListItemIcon>
          <HomeWorkIcon />
        </ListItemIcon>
        <ListItemText primary="Cities" />
      </ListItemButton>
    </RouterLink>

    <RouterLink to='/users'>
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
    </RouterLink>

  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>Content Manager</ListSubheader>

    <RouterLink to='/media-library'>
      <ListItemButton>
        <ListItemIcon>
          <PermMediaIcon />
        </ListItemIcon>
        <ListItemText primary="Media Library" />
      </ListItemButton>
    </RouterLink>

    <RouterLink to='/linktree'>
      <ListItemButton>
        <ListItemIcon>
          <SignpostIcon />
        </ListItemIcon>
        <ListItemText primary="Link Tree" />
      </ListItemButton>
    </RouterLink>

    <RouterLink to='/mailing-list'>
      <ListItemButton>
        <ListItemIcon>
          <OutboxIcon />
        </ListItemIcon>
        <ListItemText primary="Mailing List" />
      </ListItemButton>
    </RouterLink>

  </React.Fragment>
);