import { IsNotEmpty, IsOptional } from 'class-validator';
import { PartialType } from './type-helpers/partial-type';
import { BaseEntity } from './base-entity';

export class PublicShopRedirectEventDto extends BaseEntity {
  @IsNotEmpty()
  requestUrl!: string;

  @IsOptional()
  additionalInfo?: any;
}

// export class PublicShopRedirectEventDto extends IntersectionType(CreateShopRedirectEventDto, BaseEntity) {}

export class UpdateShopRedirectEventDto extends PartialType(PublicShopRedirectEventDto) {}
