import { Typography } from "@mui/material";
import { PublicGigDto } from "public-dto";
import { useParams } from "react-router";
import { toStr } from "../../../util/helpers";
import { useApiQuery } from '../../../hooks/useApiQuery';

export const Gig = () => {
  const { id } = useParams();
  const { isLoading, error, data } = useApiQuery<PublicGigDto>({ path: `gigs/${id}` });
  return (<>
    { isLoading && <p>Loading Gig item "{id}"</p> }
    { error && <p>Error: {toStr(error)}</p> }
    {
      !isLoading && !error && data && data && <>
        <Typography variant='h3'>{data.id}</Typography>
        <pre>{toStr(data)}</pre>
      </>

    }
  </>);
}