import { Paper, Typography } from '@mui/material';
import { Route, Outlet } from 'react-router-dom';
import { CityList } from './CityList';
import { City } from './City';

export const CitiesPage = () => {
  return <>
    <Typography variant='h2'>Cities</Typography>
    <Paper sx={{ p: 2 }}>
      <Outlet/>
    </Paper>
  </>;
};

export const CitiesRouter = <Route path='cities' element={<CitiesPage/>}>
  <Route index element={<CityList/>} />
  <Route path='create' element={<City />} />
  <Route path=':id' element={<City />} />
  <Route path='' element={<h1>Not found</h1>} /> {/* Not actually needed.. */}
</Route>;