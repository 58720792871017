import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#37474f',
      light: 'rgba(118,139,148,0.66)',
    },
    secondary: {
      main: '#dc3169',
    },
    background: {
      default: '#cfd8dc',
      paper: '#f7fafb',
    },
    info: {
      main: '#00838f',
    },
    success: {
      main: '#4ab54e',
    },
    error: {
      main: '#ef4033',
    },
    warning: {
      main: '#f79506',
    },
    divider: 'rgba(4,26,35,0.12)',
  },
};

// Create a theme instance.
const theme = createTheme(themeOptions);

export default theme;