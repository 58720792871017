import { useContext } from 'react';
import { GlobalContext, GlobalContextType } from './globalContext';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { ApplicationTopBar } from "../shared/AppBar"
import { SideNav } from "../shared/SideNav"
import { Footer } from '../shared/Footer';
import { Outlet } from 'react-router-dom';

interface MainLayoutProps {
  // ContentComponent: React.ComponentType;
  // is404?: boolean;
}

export const MainLayout = (props: MainLayoutProps) => {
  // const { ContentComponent } = props;
  const { menuOpen, setMenuOpen } = useContext(GlobalContext) as GlobalContextType;
  return <>
    <ApplicationTopBar open={menuOpen} toggleDrawer={() => setMenuOpen(!menuOpen)} />
    <SideNav open={menuOpen} toggleDrawer={() => setMenuOpen(!menuOpen)} />
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container
        maxWidth='xl'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: -8, // TODO: Magic number, matches navbar height. Find way to set this+navbar height with shared var
          height: '100%'
        }}
      >
          <Box
            flex='1 0 auto'
            mt={9} //TODO: Magic number, related to Container margin top & Navbar
          >
            {/* MAIN CONTENT */}
            {/* <ContentComponent /> */}
            <Outlet />
            {/* MAIN CONTENT END */}
          </Box>
          <Box
            flexShrink={0}
          >
            <Footer sx={{ pt: 4 }} />
          </Box>
      </Container>
    </Box>
  </>
}