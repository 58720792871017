import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { Timezone } from 'public-dto';

const timeZoneNames = Object.entries(Timezone).map(([_key, value]) => value);

export const TimezoneSelect = ({ name, control, label }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          notEmptyTesting: (v) => {
            return v?.length > 0 ? undefined : `[${name}] should not be empty!`;
          }
        }
      }}
      render={({
        field: { onChange, value, ref },
        fieldState: { error },
        // formState,
      }) => (
        <Autocomplete
          id='country-select'
          onChange={(_event: any, newValue: string | null) => {
            onChange(newValue);
          }}
          value={value || null}
          options={timeZoneNames}
          autoHighlight
          getOptionLabel={(option) => option}
          renderOption={(props, option) => (
            <Box component='li' {...props}>
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={ref}
              label={label}
              variant='standard'
              helperText={error ? error.message : null}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
    />
  );
};
