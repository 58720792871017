import { IsEmail, IsEnum, IsNotEmpty, IsOptional } from 'class-validator';
import { PartialType } from './type-helpers/partial-type';
import { BaseEntity } from './base-entity';

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  READ_ONLY = 'READ_ONLY',
}

export class PublicUserDto extends BaseEntity {
  @IsNotEmpty()
  name: string;

  @IsNotEmpty()
  @IsEmail()
  email!: string;

  @IsOptional()
  @IsEnum(UserRole, { each: true })
  roles?: UserRole[];

  @IsOptional()
  additionalInfo?: any;
}

export class UpdateUserDto extends PartialType(PublicUserDto) {}
