import { Paper, Typography } from '@mui/material';
import { Route, Outlet } from 'react-router';
import { VenueList } from './VenueList';
import { Venue } from './Venue';

export const VenuesPage = () => {
  return <>
    <Typography variant='h2'>Venues</Typography>
    <Paper sx={{ p: 2 }}>
      <Outlet />
    </Paper>
  </>;
}

export const VenuesRouter = <Route path='/venues' element={<VenuesPage/>}>
  <Route index element={<VenueList/>} />
  <Route path='create' element={<h1>Create venue here</h1>} />
  <Route path=':id' element={<Venue />} />
  <Route path='' element={<h1>VenuesRouter, Not Found</h1>} /> {/* Not actually needed.. */}
</Route>