import './loaderPage.css';

export const LoaderPage = () => {
  return (
    <div className='loaderContainer'>
      <div className='loader'>
        <div className='face'>
          <div className='circle'></div>
        </div>
        <div className='face'>
          <div className='circle'></div>
        </div>
      </div>
    </div>
  )
}