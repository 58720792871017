import { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { PublicCityDto, UpdateCityDto } from 'public-dto';
import { useParams } from 'react-router';
import { toStr, typedEntries } from '../../../util/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { useApiQuery } from '../../../hooks/useApiQuery';
import { useApiMutation } from '../../../hooks/useApiMutation';
import { FormInputText } from '../../form-components/FormInputText';
import { CountrySelect } from '../../form-components/CountrySelect';
import { TimezoneSelect } from '../../form-components/TimezoneSelect';
import { countries, CountryType } from 'public-dto';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const City = () => {
  const { id } = useParams();
  const creatingNew = isNaN(parseInt(id || ''));
  const navigate = useNavigate();
  const queryKey = { path: `cities/${id}`, enabled: !creatingNew };
  const { isLoading, error, data } = useApiQuery<PublicCityDto>(queryKey);
  const queryClient = useQueryClient();
  const patchMutation = useApiMutation<UpdateCityDto>({ path: `cities/${id}`, axiosConfig: { method: 'patch' } });
  const postMutation = useApiMutation<UpdateCityDto>({ path: `cities`, axiosConfig: { method: 'post' } });
  const { handleSubmit, reset, control, setValue, watch } = useForm<UpdateCityDto & {countrySelect: CountryType}>({ reValidateMode: 'onChange' });
  const onSubmit = (data: UpdateCityDto & {countrySelect: CountryType}) => {
    data.countryCode = data.countrySelect?.code;
    data.countryName = data.countrySelect?.label;
    if (creatingNew) {
      postMutation.mutate(data, {
        onError: (err) => {
          console.log(err);
        },
        onSuccess: (mutatedData) => {
          console.log(mutatedData);
          navigate(`/cities/${mutatedData.id}`);
        }
      })
    } else {
      patchMutation.mutate(data, {
        onError: (err) => {
          console.log(err);
        },
        onSuccess: (mutatedData) => {
          console.log(mutatedData);
          queryClient.setQueryData([queryKey], mutatedData);
        }
      });
    }
  };
  useEffect(() => {
    if (data) {
      typedEntries(data).forEach(e => {
        const name = e?.[0];
        const val = e?.[1];
        if (name && val) {
          setValue(name, val);
        }
      })
      const country = countries.find(c => c.code === data.countryCode);
      if (country) {
        setValue('countrySelect', country);
      }
    }
  }, [data, setValue]);
  const watchName = watch(['name']);
  return (<>
    { !creatingNew && isLoading && <p>Loading city '{id}'</p> }
    { error && <p>Error: {toStr(error)}</p> }
    {
      (!isLoading || creatingNew) && !error && (data || creatingNew) && <>
        <Typography variant='h4' mb={3}>{watchName}</Typography>
        <form>
          <Grid container spacing={2} maxWidth='sm'>
            {
              !creatingNew &&
              <Grid item xs={12}>
                <FormInputText name='id' control={control} type='number' disabled label='Id' />
              </Grid>
            }
            <Grid item xs={12}>
              <FormInputText name='name' control={control} label='Name' />
            </Grid>
            <Grid item xs={12}>
              <CountrySelect name='countrySelect' control={control} label='Country'/>
            </Grid>
            <Grid item xs={12}>
              <TimezoneSelect name='timezoneName' control={control} label='Timezone' />
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='primary' onClick={handleSubmit(onSubmit)} sx={{ mr: 2, my: 2 }}>Submit</Button>
              <Button variant='outlined' color='secondary' onClick={() => reset()}>Reset</Button>
            </Grid>
          </Grid>
        </form>
      </>
    }
  </>);
}