import { IsNotEmpty, IsOptional } from 'class-validator';
import { Timezone } from './timezones.enum';
import { PartialType } from './type-helpers/partial-type';
import { BaseEntity } from './base-entity';

export class PublicCityDto extends BaseEntity {
  @IsNotEmpty()
  name: string;

  @IsNotEmpty()
  countryCode!: string;

  @IsNotEmpty()
  countryName!: string;

  @IsOptional()
  timezoneName?: Timezone;
}

// export class PublicCityDto extends IntersectionType(CreateCityDto, BaseEntity) {}

export class UpdateCityDto extends PartialType(PublicCityDto) {}
