import { Button, Grid } from "@mui/material";
import { PublicVenueDto, UpdateVenueDto } from "public-dto";
import { useNavigate, useParams } from "react-router";
import { toStr, typedEntries } from "../../../util/helpers";
import { useApiQuery } from '../../../hooks/useApiQuery';
import { useQueryClient } from "@tanstack/react-query";
import { useApiMutation } from "../../../hooks/useApiMutation";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { FormInputText } from "../../form-components/FormInputText";

export const Venue = () => {
  const { id } = useParams();
  const creatingNew = isNaN(parseInt(id || ''));
  const navigate = useNavigate();
  const queryKey = { path: `venues/${id}`, enabled: !creatingNew };
  const queryClient = useQueryClient();
  const { isLoading, error, data } = useApiQuery<PublicVenueDto>({ path: `venues/${id}` });
  const patchMutation = useApiMutation<UpdateVenueDto>({ path: `venues/${id}`, axiosConfig: { method: 'patch' } });
  const postMutation = useApiMutation<UpdateVenueDto>({ path: 'venues', axiosConfig: { method: 'post' } });
  const resolver = classValidatorResolver(PublicVenueDto);
  const { handleSubmit, reset, control, setValue } = useForm<UpdateVenueDto>({ reValidateMode: 'onChange', resolver });
  const onSubmit = (data: UpdateVenueDto) => {
    if (creatingNew) {
      postMutation.mutate(data, {
        onError: (err) => {
          console.log(err);
        },
        onSuccess: (mutatedData) => {
          console.log(mutatedData);
          navigate(`/venues/${mutatedData.id}`);
        }
      })
    } else {
      patchMutation.mutate(data, {
        onError: (err) => {
          console.log(err);
        },
        onSuccess: (mutatedData) => {
          console.log(mutatedData);
          queryClient.setQueryData([queryKey], mutatedData);
        }
      });
    }
  };
  useEffect(() => {
    if (data) {
      typedEntries(data).forEach(e => {
        const name = e?.[0];
        const val = e?.[1];
        if (name && val) {
          setValue(name, val);
        }
      })
    }
  }, [data, setValue]);
  return (<>
    { !creatingNew && isLoading && <p>Loading venue '{id}'</p> }
    { error && <p>Error: {toStr(error)}</p> }
    {
      (!isLoading || creatingNew) && !error && (data || creatingNew) && <>
        <form>
          <Grid container spacing={2} maxWidth='md'>
            {
              !creatingNew &&
              <Grid item xs={12}>
                <FormInputText name='id' control={control} type='number' disabled label='Id' />
              </Grid>
            }
            <Grid item xs={12}>
              <FormInputText name='name' control={control} label='Title' />
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='primary' onClick={handleSubmit(onSubmit)} sx={{ mr: 2, my: 2 }}>Submit</Button>
              <Button variant='outlined' color='secondary' onClick={() => reset()}>Reset</Button>
            </Grid>
          </Grid>
        </form>
      </>
    }
  </>);
}